import React from "react";
import ServicePage from "../../components/ServicePage";
import PageMeta from "../../components/PageMeta";

export default function FoodPreparationPage() {
  return (
    <ServicePage>
      <PageMeta
        title="QCL Services - Food preparation"
        path="services/food-preparation"
      />
      <h1 className="top">Food Preparation</h1>
      <p className="lead">
        Care staff are trained and certified in Food Preparation and Basic
        Hygiene.
      </p>
      <p>
        We do not provide a fully cooked meal from basic ingredients but provide
        a Basic Food Preparation Service that typically includes a light
        meal/snack i.e.:-
      </p>
      <ul>
        <li>Sandwich</li>
        <li>Toast</li>
        <li>Cereal</li>
        <li>Heating microwave meals</li>
        <li>Thermos flask preparation</li>
      </ul>
      <p>
        Usually, food preparation is included within a personal care package and
        is not provided as a separate service.
      </p>
    </ServicePage>
  );
}
